<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">房间管理</div>
        <div class="rb1" @click="roomadd">
          <img src="@/assets/add.png" class="icon" />
          <router-link to="/boss/bar_add">
            <div class="text">添加</div>
          </router-link>
        </div> 
        <div class="rb" @click="goback">
          <img src="@/assets/fh.png" class="icon" />
          <div class="text">返回</div>
        </div>      
      </div>
      <div class="content">
        <div class="items">         
          <div class="item">
            <div class="box">
              <div class="name">
                <input type="text" v-model="roomname" class="input qy" placeholder="查询房间" />
                <div class="rb2" @click="queryroom">
                  <img src="@/assets/cx.png" class="icon" />
                  <div class="text">查询</div>
                </div>
                <select class="form_select" v-model="selectItem" @change="selectFn($event)">
                    <option v-for="ztitem in ztitems" :key="ztitem.id" :value="ztitem.id">{{ztitem.name}}</option>
                </select>
              </div>           
            </div>           
          </div>
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item" v-for="item in items" :key="item.id">
            <div class="box">
              <div class="name">
                <span class="roomcode">{{ item.room_name }}</span>
                <span class="region r">{{ item.zone_name }}</span>
                <span class="region">{{ item.room_floor }}楼</span>
              </div>
              <div class="name1">
                <span class="region f">电脑总数：{{ item.num_computer }}台</span>
              </div>
              <div class="unames">                
                <img src="@/assets/rsq.png" v-if="item.has_heater==='1'" class="pz active" />
                <img src="@/assets/kt.png" v-if="item.has_ac==='1'" class="pz active" />
                <img src="@/assets/ch.png" v-if="item.has_window==='1'" class="pz active" />
                <img src="@/assets/xsj.png" v-if="item.has_toilet==='1'" class="pz active" />
                <img src="@/assets/dan.png" v-if="item.room_type==='00000001'" class="pz active" />
                <img src="@/assets/shuang.png" v-if="item.room_type==='00000002'" class="pz active" />
                <img src="@/assets/jia.png" v-if="item.room_type==='00000003'" class="pz active" />
                <img src="@/assets/biao.png" v-if="item.room_type==='00000004'" class="pz active" />
                <img src="@/assets/shang.png" v-if="item.room_type==='00000005'" class="pz active" />
                <img src="@/assets/hao.png" v-if="item.room_type==='00000006'" class="pz active" />
                <img src="@/assets/duo.png" v-if="item.room_type==='00000007'" class="pz active" />                
              </div>
            </div>
            <div class="box">
              <div class="name1">                              
                <span v-if="item.checkin_type==='1'" class="state">入住</span>
                <span v-if="item.checkin_type==='0'" class="state zt">空闲</span>
                <span class="floor" @click="onSetPass(item)">设置密码</span>
              </div>
              <div class="name1">                              
                <span class="floor r" @click="onSetIP(item)">设置IP</span>
                <span class="floor" @click="onSetComputer(item)">设置电脑数</span>
              </div>
              <div class="unames">
                <div class="view active" @click="onItemClick(item)">查看二维码</div>
                <div v-if="item.checkin_type==='0'">
                  <img src="@/assets/del.png" @click="onDelete(item)" class="del active" />
                </div>                
                <!--<img src="@/assets/edit.png" @click="onEdit(item)" class="edit active" />-->
              </div>             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
export default {
  created() {
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;
    this.queryroom();
  },
  data() {
    return {
      type: '',
      unionid: '',
      srole: true,
      selectItem: '99',
      selectQyItem: '00000000',
      roomname: '',
      ztitems: [
          {id:'99', name: '全部'},
          {id:'1', name: '入住'},
          {id:'0', name: '空闲'}
      ],
      items: [
        // {
        //   id:'1', 
        //   room_name: '1002', 
        //   zone_name: 'A区', 
        //   room_floor: '5', 
        //   has_heater: '1', 
        //   has_ac: '1', 
        //   has_window: '1', 
        //   has_toilet: '1', 
        //   checkin_type: '1', 
        //   num_computer: '3', 
        //   room_type: '00000005'
        // },
        // {
        //   id:'2', 
        //   room_name: '1003', 
        //   zone_name: 'B区', 
        //   room_floor: '1', 
        //   has_heater: '1', 
        //   has_ac: '1', 
        //   has_window: '1', 
        //   has_toilet: '1', 
        //   checkin_type: '0', 
        //   num_computer: '5', 
        //   room_type: '00000003'
        // },
        // {
        //   id:'3', 
        //   room_name: '1005', 
        //   zone_name: 'A区', 
        //   room_floor: '3', 
        //   has_heater: '1', 
        //   has_ac: '1', 
        //   has_window: '1', 
        //   has_toilet: '1', 
        //   checkin_type: '0', 
        //   num_computer: '8', 
        //   room_type: '00000006'
        // }
      ]
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/admin/admin_index', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    },
    roomadd() {
      this.$router.replace({path:'/boss/room_add', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    },
    selectFn(e) {
      //Toast(e.target.value);
      // console.log(e)
      // console.log(e.target.selectedIndex) // 选择项的index索引
      // console.log(e.target.value) // 选择项的value
      this.queryroom();
    },
    async queryroom() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_selectroom_tj_lb',
        qs.stringify({
          zone_code: this.selectQyItem,
          checkin_type: this.selectItem,
          room_code: this.roomname
        })
      );
      if (data.flag === '0') {
        //this.items = data.data;   
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
      }else{
        Dialog({ message: "查询房间列表失败" });
      }
    },
    onSetIP(item) {
      // Toast("设置IP");
      this.$router.replace({path:'/boss/room_set_ip_list', query:{
        type:this.type,
        unionid:this.unionid,
        srole:this.srole,
        num_computer:item.num_computer,
        room_code:item.room_code
      }});
    },
    onSetComputer(item) {
      // Toast("设置电脑数");
      this.$router.replace({path:'/boss/room_set_computer', query:{
        type:this.type,
        unionid:this.unionid,
        srole:this.srole,
        num_computer:item.num_computer,
        room_code:item.room_code
      }});
    },
    onSetPass(item) {
      this.$router.replace({path:'/boss/room_pass', query:{
        type:this.type,
        unionid:this.unionid,
        srole:this.srole,
        room_code:item.room_code
      }});
    },
    onItemClick(item) {
      this.$router.replace({path:'/boss/room_ewm_detail', query:{
        type:this.type,
        unionid:this.unionid,
        srole:this.srole,
        room_name:item.room_name,
        room_code:item.room_code
      }});
    },
    onDelete(item) {
      Dialog.confirm({
        message: '是否删除房间[' + item.room_name + ']?'
      }).then(async () => {
        let { data } = await this.axios.post(
          '/ctid/TldjService/dj_deleteroom', 
        qs.stringify({ 
          roomcode: item.room_code 
        }));
        if (data.flag === '0') {
          Toast(data.message);
          this.queryroom();
        } else {
          Dialog({ message: data.message });
        }
      });
    }
    
  }
};
</script>

<style lang="less" scoped>

.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 60px;
    color: #fff;
    font-size: 36px;
    float: left;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    .name {
      line-height: 76px;
      overflow: hidden;
      font-size: 32px;
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
      .roomcode {
        font-size: 28px;
        margin-right: 20px;
      }
      .region {
        font-size: 26px;
        color: rgb(136, 107, 192);
        &.r {
          margin-right: 20px;
          color: rgb(25, 162, 172);
        }
        &.f {
         font-size: 28px;
         color: rgb(109, 144, 161);
        }
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        width: 260px;
        border: 0;
        color: #333;
        float: left;
        &.qy {
          font-size: 30px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      .rb2 {
        height: 60px;
        width: 125px;
        float: left;
        overflow: hidden;
        background: rgb(130, 189, 217);
        margin-top: 16px;
        border-radius: 15px;
        .icon {
          width: 30px;
          height: 30px;
          margin: 14px 5px 14px 13px;
          display: block;
          float: left;
        }        
        .text {
          float: left;
          color: #fff;
          font-size: 30px;
          line-height: 60px;
        }
      }
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 80px center no-repeat;
        background-size: 26px;
        width: 110px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color: rgb(184, 188, 190);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
        //direction: rtl;
        //padding-right: 60px;
      }
      select:focus { outline: none; }
    }
    .name1 {
      line-height: 70px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .region {
        font-size: 26px;
        color: rgb(184, 188, 190);
        &.r {
          margin-right: 20px;
        }
        &.f {
         font-size: 28px;
         color: rgb(150, 150, 150);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(155, 157, 158);
        margin-right: 20px;
        &.r {
          margin-right: 0px;
        }
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-top: 8px;
      
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 8px;
      width: 156px;
      height: 46px;
      border: 1px solid rgba(157, 139, 206, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 46px;
      color: rgba(157, 139, 206, 1);
      font-size: 26px;
      float: right;
      margin-left: 20px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
</style>
